import { calc } from '@vanilla-extract/css-utils';

import { capHeightVar } from '../../styles/sprinkles.css';
import { BoxOwnProps } from '../Box';

interface useIconPropsOptions {
  style?: React.CSSProperties;
  height: React.SVGAttributes<SVGElement>['height'];
  textCenter?: boolean;
}

const useIconProps = (props: useIconPropsOptions) => {
  const height = typeof props.height === 'number' ? `${props.height}px` : props.height;
  const marginVert = (props.textCenter && height !== undefined) ? calc.multiply(
    calc.subtract(capHeightVar, height),
    0.5,
  ) : undefined;
  const style = marginVert === undefined ? props.style : { ...(props.style ?? {}), marginBottom: marginVert, marginTop: marginVert };
  // content-box allows padding to work as expected on svg's as they all have set height/width
  return { style: { boxSizing: 'content-box' as const, ...style } };
};

export default useIconProps;

export type IconDefaultProps = Omit<React.SVGAttributes<SVGElement>, 'cursor'> & BoxOwnProps & { textCenter?: boolean; };
