var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { backgrounds } from '../../styles/theme.css';
import Box from '../Box';
import Flex from '../Flex';
import Grid from '../Grid/Grid';
import Text from '../Text';
import * as styles from './Banner.css';
var Banner = React.forwardRef(function (props, ref) {
    var _a = props.palette, palette = _a === void 0 ? 'orangeFill' : _a, _b = props.border, border = _b === void 0 ? false : _b, rest = __rest(props, ["palette", "border"]);
    return (<Grid ref={ref} background={palette} foreground={backgrounds[palette].lightness ? 'black' : 'white'} paddingY='space-1.5' paddingX='space-1' borderRadius='medium' gridTemplateAreas={{
            desktop: "\n          \"icon title buttons\"\n          \"icon left buttons\"\n        ",
            tablet: "\n          \"icon title\"\n          \"icon left\"\n          \"icon buttons\"\n        ",
        }} gridTemplateColumns='auto 1fr auto' gridTemplateRows={{ desktop: 'auto 1fr', tablet: 'auto 1fr auto' }} {...rest} className={[props.className, border ? styles.borders({ palette: palette }) : undefined]}/>);
});
var Title = function (props) {
    var children = props.children, variant = props.variant, rest = __rest(props, ["children", "variant"]);
    var styleProps = (function () {
        if (variant === 'bold') {
            return {
                foreground: 'black',
                fontWeight: 'bold',
            };
        }
        // variant === faint
        return {
            foreground: 'greyDark',
            fontSize: 'small',
        };
    })();
    var child = typeof children === 'string'
        ? (<Text {...styleProps}>{children}</Text>)
        : children;
    return (<Box as='h4' gridArea='title' 
    // This will make the title area overlap into the button column, which
    // should always be visually empty as the buttons are centered vertically
    gridColumnStart='title-start' gridColumnEnd={{ desktop: 'buttons-end', tablet: 'title-end' }} paddingBottom='space-1' {...styleProps} {...rest}>
      {child}
    </Box>);
};
var Icon = function (props) {
    return (<Box display='flex' gridArea='icon' alignSelf='center' paddingRight='space-0.5' {...props}/>);
};
var Content = function (props) {
    var children = props.children;
    var child = typeof children === 'string'
        ? (<Text>{children}</Text>)
        : children;
    return (<Box gridArea='left' alignSelf='center' {...props}>
      {child}
    </Box>);
};
var Buttons = function (props) {
    return (<Flex gridArea='buttons' alignSelf='center' gap='space-1' paddingLeft={{ desktop: 'space-2', tablet: 'none' }} paddingTop={{ tablet: 'space-1' }} flexDirection={props.flexDirection} {...props}/>);
};
export default Object.assign(Banner, {
    Content: Content,
    Buttons: Buttons,
    Title: Title,
    Icon: Icon,
});
