var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PressResponder } from '@react-aria/interactions';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useRef } from 'react';
import { mergeProps, useFocusWithin, useModal, useOverlayPosition, usePress, useTooltip, useTooltipTrigger } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';
import { cn } from '../../webutils/webutils';
import Box from '../Box';
import { OverlayContainerSSR } from '../Portal';
import * as styles from './Tooltip.css';
var tooltipContext = React.createContext(null);
var Tooltip = function (props) {
    var tooltipState = props.tooltipState, _a = props.trigger, trigger = _a === void 0 ? undefined : _a, children = props.children, isDisabled = props.isDisabled;
    var tooltipRef = useRef(null);
    var triggerRef = useRef(null);
    var internalState = useTooltipTriggerState({ delay: 0 });
    var state = tooltipState || internalState;
    var _b = useTooltipTrigger({
        delay: 0,
        trigger: trigger,
        isDisabled: isDisabled,
    }, state, triggerRef), triggerProps = _b.triggerProps, triggerTooltipProps = _b.tooltipProps;
    var value = {
        tooltipRef: tooltipRef,
        triggerRef: triggerRef,
        state: state,
        triggerProps: triggerProps,
        triggerTooltipProps: triggerTooltipProps,
    };
    return (<tooltipContext.Provider value={value}>
      {children}
    </tooltipContext.Provider>);
};
var Trigger = function (props) {
    var triggerElem = props.children, _a = props.openOnClick, openOnClick = _a === void 0 ? false : _a, isLegacyOnClickHandler = props.isLegacyOnClickHandler;
    var ctx = useContext(tooltipContext);
    if (ctx === null)
        throw new Error('Must render Trigger insode of Tooltip');
    var triggerRef = ctx.triggerRef, state = ctx.state, triggerProps = ctx.triggerProps;
    // Clicking/pressing the button should open the tooltip for it to be
    // accessible on mobile. This is not a part of the default behavior of the
    // tooltip as the library expects most tooltips to be on elements that perform
    // other actions on press. See this issue for more details and eventual(?)
    // resolution https://github.com/adobe/react-spectrum/issues/2008
    var pressProps = usePress({ onPressStart: function () { return state.open(true); } }).pressProps;
    if (isLegacyOnClickHandler) {
        return React.cloneElement(triggerElem, mergeProps(triggerElem.props, triggerProps, { ref: triggerRef }, openOnClick ? pressProps : {}));
    }
    return (<PressResponder {...mergeProps(triggerProps, openOnClick ? pressProps : {})} ref={triggerRef}>
      {triggerElem}
    </PressResponder>);
};
var Tip = function (props) {
    var ctx = useContext(tooltipContext);
    if (ctx === null)
        throw new Error('Must render Tip insode of Tooltip');
    // We need `useModal` to see this `OverlayContainer` to correctly indicate
    // that an overlay is on the screen to engage the top-level `OverlayProvider` to
    // aria-hide the rest of the applications content for screen readers
    return (<AnimatePresence initial={false}>
      {ctx.state.isOpen && (<OverlayContainerSSR>
          <TipInternal {...props}/>
        </OverlayContainerSSR>)}
    </AnimatePresence>);
};
var TipInternal = function (props) {
    var _a = props.closeOnInteraction, closeOnInteraction = _a === void 0 ? false : _a, _b = props.placement, placement = _b === void 0 ? 'bottom' : _b, _c = props.offset, offset = _c === void 0 ? 8 : _c, positionProps = props.positionProps, children = props.children;
    var ctx = useContext(tooltipContext);
    if (ctx === null)
        throw new Error('Must render Tip insode of Tooltip');
    var state = ctx.state, triggerRef = ctx.triggerRef, tooltipRef = ctx.tooltipRef, triggerTooltipProps = ctx.triggerTooltipProps;
    var tooltipProps = useTooltip(triggerTooltipProps, state).tooltipProps;
    var _d = useOverlayPosition(__assign({ targetRef: triggerRef, overlayRef: tooltipRef, offset: offset, placement: placement, isOpen: state.isOpen, shouldUpdatePosition: true, shouldFlip: true }, positionProps)), overlayProps = _d.overlayProps, arrowProps = _d.arrowProps, calculatedPlacement = _d.placement;
    var modalProps = useModal().modalProps;
    // By default the tooltip dismisses itself on interaction, but in the case
    // that it, for example, contains a link, we need to allow it to stay open
    var pressProps = usePress({ onPressStart: function () { return state.open(true); } }).pressProps;
    var focusWithinProps = useFocusWithin({ onFocusWithin: function () { return state.open(true); } }).focusWithinProps;
    return (<Box as={motion.div} background='blueSpace' foreground='white' borderRadius='small' paddingY='space-0.5' paddingX='space-0.75' fontSize='small' shadow='normal' initial={{ opacity: 0, y: 4 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 4 }} transition={{ duration: 0.15 }} {...mergeProps(
    // These types are never going to practially intersect poorly, but
    // good luck telling typescript that because of the random motion values
    /* eslint-disable @typescript-eslint/no-explicit-any */
    tooltipProps, overlayProps, modalProps, closeOnInteraction ? {} : pressProps, closeOnInteraction ? {} : focusWithinProps)} ref={tooltipRef}>
      <span {...arrowProps} className={cn(styles.tooltipArrow, styles.placements[calculatedPlacement])}/>
      {children}
    </Box>);
};
export default Object.assign(Tooltip, { Tip: Tip, Trigger: Trigger });
