import React from 'react';

import Box from '../Box';

import useIconProps, { IconDefaultProps } from './useIconProps';

export const IconWarning = (props: IconDefaultProps & { title?: string }) => {
  const { width = 28, height = 25, ...rest } = props;
  const iconProps = useIconProps({ ...rest, height });
  return (
    <Box
      as='svg'
      width={width}
      height={height}
      viewBox='0 0 28 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
      {...iconProps}
    >
      <g clipPath='url(#clip0_1511_4162)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M22.84 23.148C30.987 20.202 25.875 14.55 22.84 8.544C19.808 2.538 14.89 1.5 10.963 1.5C7.036 1.5 2 7.937 2 14.55C2 21.164 14.695 26.093 22.84 23.148Z' fill='#F8DF9B' />
        <path d='M13.4341 14.1021L13.2181 10.9431C13.1761 10.3081 12.9541 9.28107 13.4501 8.76407C13.8271 8.36707 14.7201 8.29907 14.9921 8.84907C15.2221 9.49607 15.2731 10.1921 15.1391 10.8641L14.8511 14.1161C14.8381 14.4221 14.7701 14.7231 14.6491 15.0061C14.6003 15.098 14.5275 15.1751 14.4384 15.229C14.3493 15.2828 14.2472 15.3116 14.1431 15.3121C14.039 15.3135 13.9364 15.2868 13.8463 15.2347C13.7561 15.1825 13.6818 15.107 13.6311 15.0161C13.5171 14.7231 13.4511 14.4151 13.4341 14.1021ZM14.1811 18.4421C13.9277 18.4432 13.6834 18.3477 13.4978 18.1752C13.3123 18.0026 13.1994 17.7659 13.1821 17.5131C13.1697 17.2605 13.2543 17.0127 13.4186 16.8204C13.583 16.6282 13.8146 16.5061 14.0661 16.4791C14.4381 16.4351 14.8041 16.5971 15.0161 16.8991C15.12 17.0465 15.1807 17.22 15.1913 17.4C15.2019 17.5801 15.162 17.7595 15.0761 17.9181C14.9882 18.0782 14.8586 18.2114 14.701 18.3037C14.5434 18.396 14.3637 18.4438 14.1811 18.4421Z' fill='#111111' />
        <path d='M23.257 21.5501H5.09302C4.6689 21.5455 4.25314 21.4315 3.88596 21.2192C3.51878 21.0068 3.2126 20.7034 2.99702 20.3381C2.78569 19.9766 2.67432 19.5653 2.67432 19.1466C2.67432 18.7278 2.78569 18.3166 2.99702 17.9551L12.089 3.68508C12.3137 3.33523 12.623 3.04776 12.9884 2.84935C13.3537 2.65093 13.7633 2.54799 14.179 2.55008C15.029 2.55208 15.819 2.98408 16.265 3.69208L25.343 17.9381C25.78 18.6761 25.785 19.5861 25.356 20.3291C25.1411 20.6965 24.8348 21.0021 24.4668 21.2161C24.0988 21.4301 23.6817 21.5451 23.256 21.5501H23.257ZM13.477 4.51008L4.38302 18.7801C4.31615 18.9008 4.28224 19.037 4.2847 19.175C4.28716 19.3129 4.32589 19.4478 4.39702 19.5661C4.46949 19.6859 4.57139 19.7851 4.69303 19.8544C4.81468 19.9238 4.95202 19.9608 5.09202 19.9621H23.256C23.3974 19.9603 23.5359 19.9221 23.6581 19.8511C23.7804 19.7802 23.8823 19.6789 23.954 19.5571C24.0244 19.4366 24.0615 19.2996 24.0615 19.1601C24.0615 19.0206 24.0244 18.8835 23.954 18.7631L14.873 4.50908C14.797 4.39347 14.6934 4.29863 14.5715 4.23315C14.4497 4.16768 14.3134 4.13362 14.175 4.13408C14.0365 4.13346 13.9 4.16743 13.778 4.23291C13.6559 4.2984 13.5521 4.39333 13.476 4.50908L13.477 4.51008Z' fill='#111111' />
      </g>
      <defs>
        <clipPath id='clip0_1511_4162'>
          <rect width='28' height='24' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>

    </Box>
  );
};

export default IconWarning;
