import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { useRef } from 'react';
import { AriaLinkOptions, mergeProps, useHover, useLink } from 'react-aria';

import { backgrounds } from '../../styles/theme.css';
import { composeRefs } from '../../util';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import { BoxOwnProps } from '../Box';
import Button, { ButtonOwnProps, useButtonStyles } from '../Button';
import Flex from '../Flex';
import Loading from '../Loading';
import Text from '../Text';
import { hrefObject, matchProtocolUrl, stringifyHref } from '../TextLink';

import * as styles from './ButtonLink.css';

export type ButtonLinkProps = ButtonOwnProps
  & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'className' | 'href'>
  & Pick<
    NextLinkProps,
    'replace' | 'scroll' | 'shallow' | 'passHref' | 'prefetch' | 'locale'
  >
  & {
    className?: BoxOwnProps['className'];
    disabled?: boolean;
    href: string | hrefObject;
    external?: boolean;
  }

export const ButtonLink = React.forwardRef((props: ButtonLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
  const {
    disabled: givenDisabled,
    isLoading,
    external,
    href,
    className,
    variant = 'default',
    palette = 'orangeCTA',
    children,
    size = 'medium',
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
    locale,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    ...rest
  } = props;
  const disabled = givenDisabled || isLoading;
  const linkRef = useRef<HTMLAnchorElement>(null);
  const stringHref = stringifyHref(href);
  const isExternal = external || matchProtocolUrl(stringHref);

  const { linkProps, isPressed } = useLink({
    // Need to cast because react aria expects event handlers for HTMLElement,
    // which conflict with ours for HTMLButtonElement. The types are compatible,
    // but typescript isn't smart enough to know that.
    ...rest as AriaLinkOptions,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    isDisabled: disabled,
  }, linkRef);
  const { hoverProps, isHovered } = useHover({
    isDisabled: disabled,
  });

  const buttonStyles = useButtonStyles({
    className,
    disabled,
    isHovered,
    isLoading,
    isPressed,
    palette,
    variant,
    size,
  });

  const Anchor = (
    <Flex
      as='a'
      display='inline-flex'
      position='relative'
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      href={stringHref}
      {...mergeProps(hoverProps, rest)}
      // We want this to override click handlers
      {...linkProps}
      ref={composeRefs(linkRef, ref)}
      className={[styles.linkReset, buttonStyles.className]}
      fontSize={buttonStyles.fontSize}
      justifyContent='center'
    >
      <Flex visibility={isLoading ? 'hidden' : undefined} fontSize={buttonStyles.fontSize} alignItems='center'>
        {childrenAreLiteral(children)
          ? <Text foreground='inherit' fontSize={buttonStyles.fontSize}>{children}</Text>
          : children}
      </Flex>
      {isLoading && (
        <Flex
          position='absolute'
          top={0}
          left={0}
          placeItems='center'
          minHeight='100%'
          minWidth='100%'
        >
          <Loading
            strokeWidth={8}
            color={(backgrounds[palette].lightness === 'dark' && variant === 'default')
              ? 'white'
              : 'grey'}
            size={30}
          />
        </Flex>
      )}
    </Flex>
  );

  if (isExternal) {
    return Anchor;
  }

  return (
    <NextLink
      href={stringHref}
      scroll={scroll}
      shallow={shallow}
      replace={replace}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      {Anchor}
    </NextLink>
  );
});

ButtonLink.displayName = 'ButtonLink';

export default Object.assign(ButtonLink, {
  Bleed: Button.Bleed,
});
