import * as t from 'io-ts';
import isEmpty from 'lodash/isEmpty';

import { ensureMableError, isMableError, isNonBlankString, isTruthy } from '@mablemarket/common-lib';

const httpUrlOrError = (s: string) => {
  try {
    const hasProtocol = /^https?:\/\//i.test(s);
    const url = new URL(hasProtocol ? s : `https://${s}`);
    return url;
  } catch (e) {
    return ensureMableError(e);
  }
};

const isVimeoLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);

  return !!url.hostname
    && url.hostname.includes('vimeo.com')
    && pathParts[0] === 'video'
    && isNonBlankString(pathParts[1]);
};

const isYoutubeDomain = (url: URL) => {
  return url.hostname.includes('youtube.com') || url.hostname.includes('youtu.be');
};

const isYoutubeShareLink = (url: URL) => {
  return url.hostname.includes('youtu.be') && isNonBlankString(url.pathname);
};

const isYoutubeQueryParamLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);
  const isWatchQuery = pathParts[0] === 'watch' && isNonBlankString(url.searchParams.get('v'));
  return isWatchQuery && isYoutubeDomain(url);
};

const isYoutubeWatchLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);
  const isWatchPath = pathParts[0] === 'watch' && isEmpty(url.searchParams.get('v')) && isNonBlankString(pathParts[1]);
  return isWatchPath && isYoutubeDomain(url);
};

const isYouTubeShortsLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);
  const isShorts = pathParts[0] === 'shorts' && isNonBlankString(pathParts[1]);
  return isShorts && isYoutubeDomain(url);
};

const isYoutubeLink = (url: URL) => {
  return isYouTubeShortsLink(url) || isYoutubeQueryParamLink(url) || isYoutubeWatchLink(url) || isYoutubeShareLink(url);
};

export const isVideoLink = (s: unknown): s is VideoLink => {
  if (typeof s !== 'string') return false;
  const url = httpUrlOrError(s);
  if (isMableError(url)) return false;

  return isYoutubeLink(url) || isVimeoLink(url);
};

export type VideoLink = string;

export const VideoLink = new t.Type<string, string, string>(
  'VideoLink',
  (u): u is VideoLink => isVideoLink(u),
  (s, c) => {
    const url = httpUrlOrError(s);
    if (isMableError(url)) {
      return t.failure(s, c, 'Must be a valid url.');
    }
    if (isVimeoLink(url)) {
      url.search = '';
      url.hash = '';
      return t.success(url.toString());
    }
    if (isYoutubeShareLink(url)) {
      const pathParts = url.pathname.split('/').filter(isTruthy);
      if (pathParts[0] === 'watch') {
        url.pathname = `/${pathParts[0]}/${pathParts[1]}`;
      } else {
        url.pathname = `/${pathParts[0]}`;
      }
      url.search = '';
      url.hash = '';
      return t.success(url.toString());
    }
    if (isYoutubeWatchLink(url) || isYouTubeShortsLink(url)) {
      const pathParts = url.pathname.split('/').filter(isTruthy);
      url.pathname = `/${pathParts[0]}/${pathParts[1]}`;
      url.search = '';
      url.hash = '';
      return t.success(url.toString());
    }
    if (isYoutubeQueryParamLink(url)) {
      url.pathname = '/watch';
      url.hash = '';
      return t.success(url.toString());
    }
    if (isVideoLink(s)) {
      return t.success(s);
    }
    return t.failure(s, c, 'Must be a valid video link. Only YouTube & Vimeo URLs are supported at this time.');
  },
  (a) => {
    return a;
  },
);

const isFacebookLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);

  return url.hostname.includes('facebook.com')
    && pathParts[1] === 'posts';
};

const isInstagramLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);

  if (!url.hostname.includes('instagram.com')) return false;
  if ((pathParts[0] === 'p' || pathParts[0] === 'reel') && pathParts.length === 2) return true;
  // Url may optionally contain account slug in first part of path
  if ((pathParts[1] === 'p' || pathParts[1] === 'reel') && pathParts.length === 3) return true;
  return false;
};

const isTikTokLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);

  return url.hostname.includes('tiktok.com')
    && pathParts[0].startsWith('@')
    && pathParts[1] === 'video'
    && pathParts.length === 3;
};

const isXLink = (url: URL) => {
  const pathParts = url.pathname.split('/').filter(isTruthy);

  return (url.hostname.includes('x.com') || url.hostname.includes('twitter.com'))
    && pathParts[1] === 'status'
    && pathParts.length === 3;
};

export const isSocialLink = (s: unknown): s is SocialLink => {
  if (typeof s !== 'string') return false;
  const url = httpUrlOrError(s);
  if (isMableError(url)) return false;

  return isFacebookLink(url) || isInstagramLink(url) || isTikTokLink(url) || isXLink(url);
};

export type SocialLink = string;

export const SocialLink = new t.Type<string, string, string>(
  'SocialLink',
  (u): u is SocialLink => isSocialLink(u),
  (s, c) => {
    const url = httpUrlOrError(s);
    if (isMableError(url)) {
      return t.failure(s, c, 'Must be a valid url.');
    }
    if (isFacebookLink(url)) {
      url.pathname = url.pathname.split('/').filter(isTruthy).slice(0, 3).join('/');
      url.search = '';
      url.hash = '';
      return t.success(url.toString());
    }
    if (isInstagramLink(url)) {
      const parts = url.pathname.split('/').filter(isTruthy);
      // if present, remove username from /username/p/post-id style pathname
      if (parts.length > 2) parts.shift();
      url.pathname = parts.slice(0, 2).join('/');
      url.search = '';
      url.hash = '';
      return t.success(url.toString());
    }
    if (isTikTokLink(url)) {
      url.pathname = url.pathname.split('/').filter(isTruthy).slice(0, 3).join('/');
      url.search = '';
      url.hash = '';
      return t.success(url.toString());
    }
    if (isXLink(url)) {
      url.pathname = url.pathname.split('/').filter(isTruthy).slice(0, 3).join('/');
      url.search = '';
      url.hash = '';
      return t.success(url.toString());
    }
    return t.failure(s, c, 'Must be a valid social link. Only Facebook, Instagram, TikTok, and Twitter/X URLs are supported at this time.');
  },
  (a) => {
    return a;
  },
);
