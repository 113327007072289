// TODO: Share this with mable-web?

import React from 'react';
import { useState, useEffect, useContext } from 'react';
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import urlJoin from 'url-join';
import jwtDecode from 'jwt-decode';
import { useRouter } from 'next/router';

const authConfig = {
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || 'auth.staging.meetmable.com',
  client_id: process.env.NEXT_PUBLIC_ADMIN_AUTH0_CLIENT_ID || '1U6raP6OzU3dz4WizXN44yRFc487SL6F',
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || 'api.staging.meetmable.com',
  redirect_uri: urlJoin((process.env.NEXT_PUBLIC_RETURN_TO || 'http://localhost:3001'), '/callback'),
};

interface Auth0ContextInterface {
  isAuthenticated: boolean;
  user: any;
  permissions?: string[];
  loading: boolean;
  handleRedirectCallback: () => void;
  getIdTokenClaims: Auth0Client['getIdTokenClaims'],
  loginWithRedirect: Auth0Client['loginWithRedirect'],
  getTokenSilently: Auth0Client['getTokenSilently'],
  logout: Auth0Client['logout']
};

export const accessToken = { current: '' };

const Auth0Context = React.createContext<Auth0ContextInterface | null>(null);

const permissionsFromJwt = (jwt: string) => jwtDecode<{ permissions?: string[] }>(jwt).permissions;

export const useAuth0 = () => {
  const context = useContext(Auth0Context);
  if (context === null) {
    throw new Error('useAuth0 must be used inside of Auth0Provider.');
  }
  return context;
};

export const Auth0Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState<string[]>();
  const router = useRouter();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(authConfig);
      setAuth0(auth0FromHook);

      if (window.location.pathname === '/callback' && window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        router.replace(appState.targetUrl || '/');
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        const token = await auth0FromHook.getTokenSilently();
        setPermissions(permissionsFromJwt(token))
        accessToken.current = token;
      } else {
        accessToken.current = '';
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  if (!auth0Client) return null;

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setUser(user);
    const token = await auth0Client.getTokenSilently();
    accessToken.current = token;
    setPermissions(permissionsFromJwt(token))
    setIsAuthenticated(true);
    setLoading(false);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        permissions,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {loading ? null : children}
    </Auth0Context.Provider>
  );
};


// If the current user has any of the given permissions or the 'everything' permission, then render
// children. Otherwise, don't.
export const UserHas: React.FC<{
  permission: string | string[],
  else?: React.ReactNode,
  children?: React.ReactNode;
}> = ({ children, permission, else: maybeElseComponent }) => {
  const elseComponent = maybeElseComponent || null;
  const { isAuthenticated, permissions: hasPermissions = [] } = useAuth0();
  if (!isAuthenticated) {
    return <>{elseComponent}</>;
  }
  let authorized = false;
  if (typeof permission === 'string') {
    authorized = hasPermissions?.includes(permission);
  } else {
    authorized = !!permission.find(p => hasPermissions.includes(p));
  }
  authorized = authorized || (permission !== 'superadmin' && hasPermissions.includes('admin'));
  return <>{authorized ? children : elseComponent}</>;
}
