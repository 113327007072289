import React from 'react';
import { accessToken, useAuth0, UserHas } from '../../Auth0Provider';

import Navbar from '../Navbar';

import styles from "./Sidebar.module.scss";

import { Desktop } from "../../ui/responsive";
import { cn } from '@mablemarket/common-react-lib';
import Omnisearch from '../Omnisearch';
import logo1 from '../../assets/admin-web-logo-1.png';
import logo2 from '../../assets/admin-web-logo-2.png';
import logo3 from '../../assets/admin-web-logo-3.png';
import logo4 from '../../assets/admin-web-logo-4.png';
import logo5 from '../../assets/admin-web-logo-5.png';
import logo6 from '../../assets/admin-web-logo-6.png';
import logo7 from '../../assets/admin-web-logo-7.png';
import logo8 from '../../assets/admin-web-logo-8.png';
import logo9 from '../../assets/admin-web-logo-9.png';
import logo10 from '../../assets/admin-web-logo-10.png';
import logo11 from '../../assets/admin-web-logo-11.png';
import logo12 from '../../assets/admin-web-logo-12.png';
import { Button } from 'react-bootstrap';
import { Box } from '@mablemarket/common-react-lib/build/components';
import Portal from '@mablemarket/common-react-lib/build/components/Portal';

const returnToDestination = () => {
  return process.env.NEXT_PUBLIC_RETURN_TO || 'http://localhost:3001';
};

interface SidebarProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void;
}

const now = new Date();

const Sidebar: React.FC<SidebarProps> = ({ isOpen, setIsOpen }) => {
  const {
    loading,
    isAuthenticated,
    logout,
    user,
  } = useAuth0();

  const handleLogout = () => {
    accessToken.current = '';
    logout({ returnTo: returnToDestination() });
  };

  const monthlyLogos = [
    logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12,
  ];

  const sidebar = (
    <>
      <div className={cn(styles.sidebar, isOpen && styles.open)}>
        <img
          className={styles.logoWrap}
          src={monthlyLogos[now.getMonth()].src}
          alt="Mable admin-web - the greatest website ever made"
        />

        {!loading && user !== undefined && (
          <div className={styles.userInfoWrap}>
            👤 {user.nickname}
          </div>
        )}

        <UserHas permission="admin">
          <div className={styles.search}> <Omnisearch /> </div>
        </UserHas>

        {isAuthenticated && <Navbar />}

        <Desktop>
          {!loading && isAuthenticated && (
            <Button
              variant='link'
              onClick={handleLogout}
            >
              Log Out
            </Button>
          )}
        </Desktop>
      </div>
    </>
  );

  return (
    <>
      <Box display={{ desktop: 'block', tablet: 'none' }} className={styles.container}>
        {sidebar}
      </Box>
      {isOpen && (
        <Portal location='floating'>
          <Box
            display={{ desktop: 'none', tablet: 'block' }}
            position='fixed'
            style={{ background: '#00000099', width: '100%', cursor: 'pointer', zIndex: 100 }}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setIsOpen(false);
              }
            }}
          >
            <Box
              className={styles.container}
              style={{ width: '350px', maxWidth: '80%', cursor: 'auto' }}
            >
              {sidebar}
            </Box>
          </Box>
        </Portal>
      )}
    </>
  );
}

export default Sidebar;
