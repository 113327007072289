import { ForwardRefComponent } from '@radix-ui/react-polymorphic';
import React from 'react';

import { backgrounds } from '../../styles/theme.css';
import Box, { BoxOwnProps } from '../Box';
import Flex, { FlexProps } from '../Flex';
import Grid, { GridProps } from '../Grid/Grid';
import Text from '../Text';

import * as styles from './Banner.css';

interface BannerOwnProps extends Pick<GridProps, 'display'> {
  palette?: keyof typeof backgrounds;
  border?: boolean;
}

interface BannerProps extends Omit<BoxOwnProps, keyof BannerOwnProps>, BannerOwnProps {
}

const Banner: ForwardRefComponent<'div', BannerProps> = React.forwardRef((props, ref) => {
  const {
    palette = 'orangeFill',
    border = false,
    ...rest
  } = props;
  return (
    <Grid
      ref={ref}
      background={palette}
      foreground={backgrounds[palette].lightness ? 'black' : 'white'}
      paddingY='space-1.5'
      paddingX='space-1'
      borderRadius='medium'
      gridTemplateAreas={{
        desktop: `
          "icon title buttons"
          "icon left buttons"
        `,
        tablet: `
          "icon title"
          "icon left"
          "icon buttons"
        `,
      }}
      gridTemplateColumns='auto 1fr auto'
      gridTemplateRows={{ desktop: 'auto 1fr', tablet: 'auto 1fr auto' }}
      {...rest}
      className={[props.className, border ? styles.borders({ palette }) : undefined]}
    />
  );
}) as ForwardRefComponent<'div', BannerProps>;

interface TitleProps {
  variant: 'bold' | 'faint';
  children?: React.ReactNode;
}
const Title = (props: TitleProps) => {
  const { children, variant, ...rest } = props;
  const styleProps = ((): BoxOwnProps => {
    if (variant === 'bold') {
      return {
        foreground: 'black',
        fontWeight: 'bold',
      };
    }
    // variant === faint
    return {
      foreground: 'greyDark',
      fontSize: 'small',
    };
  })();
  const child = typeof children === 'string'
    ? (<Text {...styleProps}>{children}</Text>)
    : children;
  return (
    <Box
      as='h4'
      gridArea='title'
      // This will make the title area overlap into the button column, which
      // should always be visually empty as the buttons are centered vertically
      gridColumnStart='title-start'
      gridColumnEnd={{ desktop: 'buttons-end', tablet: 'title-end' }}
      paddingBottom='space-1'
      {...styleProps}
      {...rest}
    >
      {child}
    </Box>
  );
};

interface IconProps {
  children?: React.ReactNode;
}
const Icon = (props: IconProps) => {
  return (
    <Box
      display='flex'
      gridArea='icon'
      alignSelf='center'
      paddingRight='space-0.5'
      {...props}
    />
  );
};

interface LeftProps {
  children?: React.ReactNode;
}
const Content = (props: LeftProps) => {
  const { children } = props;
  const child = typeof children === 'string'
    ? (<Text>{children}</Text>)
    : children;
  return (
    <Box
      gridArea='left'
      alignSelf='center'
      {...props}
    >
      {child}
    </Box>
  );
};

interface ButtonsProps {
  children?: React.ReactNode
  flexDirection?: FlexProps['flexDirection']
}
const Buttons = (props: ButtonsProps) => {
  return (
    <Flex
      gridArea='buttons'
      alignSelf='center'
      gap='space-1'
      paddingLeft={{ desktop: 'space-2', tablet: 'none' }}
      paddingTop={{ tablet: 'space-1' }}
      flexDirection={props.flexDirection}
      {...props}
    />
  );
};

export default Object.assign(Banner, {
  Content,
  Buttons,
  Title,
  Icon,
});
