import { PressResponder } from '@react-aria/interactions';
import { Placement, PositionProps } from '@react-types/overlays';
import { TooltipTriggerProps } from '@react-types/tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useRef } from 'react';
import { mergeProps, useFocusWithin, useModal, useOverlayPosition, usePress, useTooltip, useTooltipTrigger } from 'react-aria';
import { TooltipTriggerState, useTooltipTriggerState } from 'react-stately';

import { cn } from '../../webutils/webutils';
import Box from '../Box';
import { OverlayContainerSSR } from '../Portal';

import * as styles from './Tooltip.css';

interface TooltipContextValue {
  state: TooltipTriggerState;
  triggerRef: React.RefObject<HTMLElement>;
  tooltipRef: React.RefObject<HTMLDivElement>;
  triggerTooltipProps: Omit<React.HTMLAttributes<HTMLElement>, 'style'>;
  triggerProps: Omit<TooltipTriggerProps, 'style'>;
}
const tooltipContext = React.createContext<TooltipContextValue | null>(null);

interface TooltipProps {
  tooltipState?: TooltipTriggerState;
  /** If trigger is undefined, open on both hover & focus (default). 'focus' will only open on click */
  trigger?: 'focus';
  children: React.ReactNode;
  isDisabled?: boolean;
}
const Tooltip = (props: TooltipProps) => {
  const {
    tooltipState,
    trigger = undefined,
    children,
    isDisabled,
  } = props;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLElement>(null);

  const internalState = useTooltipTriggerState({ delay: 0 });
  const state = tooltipState || internalState;
  const { triggerProps, tooltipProps: triggerTooltipProps } = useTooltipTrigger({
    delay: 0,
    trigger,
    isDisabled,
  }, state, triggerRef);

  const value = {
    tooltipRef,
    triggerRef,
    state,
    triggerProps,
    triggerTooltipProps,
  };
  return (
    <tooltipContext.Provider value={value}>
      {children}
    </tooltipContext.Provider>
  );
};

interface TriggerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any>;
  openOnClick?: boolean;
  isLegacyOnClickHandler?: boolean;
}
const Trigger = (props: TriggerProps) => {
  const {
    children: triggerElem,
    openOnClick = false,
    isLegacyOnClickHandler,
  } = props;

  const ctx = useContext(tooltipContext);
  if (ctx === null) throw new Error('Must render Trigger insode of Tooltip');

  const { triggerRef, state, triggerProps } = ctx;

  // Clicking/pressing the button should open the tooltip for it to be
  // accessible on mobile. This is not a part of the default behavior of the
  // tooltip as the library expects most tooltips to be on elements that perform
  // other actions on press. See this issue for more details and eventual(?)
  // resolution https://github.com/adobe/react-spectrum/issues/2008
  const { pressProps } = usePress({ onPressStart: () => state.open(true) });

  if (isLegacyOnClickHandler) {
    return React.cloneElement(
      triggerElem,
      mergeProps(triggerElem.props, triggerProps, { ref: triggerRef }, openOnClick ? pressProps : {}),
    );
  }

  return (
    <PressResponder {...mergeProps(triggerProps, openOnClick ? pressProps : {})} ref={triggerRef}>
      {triggerElem}
    </PressResponder>
  );
};

interface TipProps {
  closeOnInteraction?: boolean;
  placement?: Placement;
  offset?: number;
  children: React.ReactNode;
  positionProps?: PositionProps,
}

const Tip = (props: TipProps) => {
  const ctx = useContext(tooltipContext);
  if (ctx === null) throw new Error('Must render Tip insode of Tooltip');
  // We need `useModal` to see this `OverlayContainer` to correctly indicate
  // that an overlay is on the screen to engage the top-level `OverlayProvider` to
  // aria-hide the rest of the applications content for screen readers
  return (
    <AnimatePresence initial={false}>
      {ctx.state.isOpen && (
        <OverlayContainerSSR>
          <TipInternal {...props} />
        </OverlayContainerSSR>
      )}
    </AnimatePresence>
  );
};

const TipInternal = (props: TipProps) => {
  const {
    closeOnInteraction = false,
    placement = 'bottom',
    offset = 8,
    positionProps,
    children,
  } = props;


  const ctx = useContext(tooltipContext);
  if (ctx === null) throw new Error('Must render Tip insode of Tooltip');
  const {
    state,
    triggerRef,
    tooltipRef,
    triggerTooltipProps,
  } = ctx;

  const { tooltipProps } = useTooltip(triggerTooltipProps, state);
  const { overlayProps, arrowProps, placement: calculatedPlacement } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: tooltipRef,
    offset,
    placement,
    isOpen: state.isOpen,
    shouldUpdatePosition: true,
    shouldFlip: true,
    ...positionProps,
  });
  const { modalProps } = useModal();
  // By default the tooltip dismisses itself on interaction, but in the case
  // that it, for example, contains a link, we need to allow it to stay open
  const { pressProps } = usePress({ onPressStart: () => state.open(true) });
  const { focusWithinProps } = useFocusWithin({ onFocusWithin: () => state.open(true) });

  return (
    <Box
      as={motion.div}
      background='blueSpace'
      foreground='white'
      borderRadius='small'
      paddingY='space-0.5'
      paddingX='space-0.75'
      fontSize='small'
      shadow='normal'
      initial={{ opacity: 0, y: 4 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 4 }}
      transition={{ duration: 0.15 }}
      {...mergeProps<React.ComponentProps<typeof motion.div>[]>(
        // These types are never going to practially intersect poorly, but
        // good luck telling typescript that because of the random motion values
        /* eslint-disable @typescript-eslint/no-explicit-any */
        tooltipProps as any,
        overlayProps as any,
        modalProps as any,
        closeOnInteraction ? {} : (pressProps as any),
        closeOnInteraction ? {} : (focusWithinProps as any),
        /* eslint-enable @typescript-eslint/no-explicit-any */
      )}
      ref={tooltipRef}
    >
      <span
        {...arrowProps}
        className={cn(styles.tooltipArrow, styles.placements[calculatedPlacement])}
      />
      {children}
    </Box>
  );
};

export default Object.assign(Tooltip, { Tip, Trigger });
