var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useRef } from 'react';
import { mergeProps, useFocusRing, useHover, useRadio, useRadioGroup, VisuallyHidden } from 'react-aria';
import { useRadioGroupState } from 'react-stately';
import { useForwardClick } from '../../hooks/useForwardClick';
import { focusRing } from '../../styles/sprinkles.css';
import { vars } from '../../styles/theme.css';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import Box from '../Box';
import Grid from '../Grid';
import Text from '../Text';
import { InputDescription, InputErrorMessage } from '../TextInput';
var RadioGroupContext = React.createContext(null);
var useRadioGroupContext = function () {
    var context = useContext(RadioGroupContext);
    if (context === null) {
        throw new Error('RadioGroup.Item must be used inside of RadioGroup.');
    }
    return context;
};
var RadioGroup = React.forwardRef(function (props, ref) {
    var label = props.label, children = props.children, error = props.error, description = props.description, disabled = props.disabled, _a = props.variant, variant = _a === void 0 ? 'default' : _a, _b = props.orientation, orientation = _b === void 0 ? 'vertical' : _b, _c = props.renderLayout, renderLayout = _c === void 0 ? function (_a) {
        var children = _a.children;
        return (<Grid gridAutoFlow={orientation === 'vertical' ? 'row' : 'column'} rowGap='space-1' columnGap='space-1.5' gridAutoColumns='max-content'>
        {children}
      </Grid>);
    } : _c, rest = __rest(props, ["label", "children", "error", "description", "disabled", "variant", "orientation", "renderLayout"]);
    var state = useRadioGroupState(__assign(__assign({}, props), { errorMessage: error, isDisabled: disabled, validationState: error ? 'invalid' : 'valid' }));
    var _d = useRadioGroup(__assign(__assign({}, props), { errorMessage: error, isDisabled: disabled, validationState: error ? 'invalid' : 'valid' }), state), labelProps = _d.labelProps, radioGroupProps = _d.radioGroupProps, descriptionProps = _d.descriptionProps, errorMessageProps = _d.errorMessageProps;
    return (<Grid {...mergeProps(radioGroupProps, rest)} gridAutoFlow='row' gap='space-0.75' ref={ref}>
      {label && (<div {...labelProps}>
          {childrenAreLiteral(label) ? <Text>{label}</Text> : label}
        </div>)}
      {renderLayout({
            children: (<RadioGroupContext.Provider value={{ state: state, variant: variant }}>
            {children}
          </RadioGroupContext.Provider>),
        })}
      {description && (<InputDescription {...descriptionProps}>{description}</InputDescription>)}
      {error && error !== true && <InputErrorMessage {...errorMessageProps}>{error}</InputErrorMessage>}
    </Grid>);
});
var RadioItem = function (props) {
    var renderIcon = props.renderIcon, rest = __rest(props, ["renderIcon"]);
    var inputRef = useRef(null);
    var forwardClickProps = useForwardClick({ targetRef: inputRef }).forwardClickProps;
    var _a = useHover({}), isHovered = _a.isHovered, hoverProps = _a.hoverProps;
    var _b = useRadioGroupContext(), state = _b.state, variant = _b.variant;
    var _c = useRadio(__assign(__assign({}, rest), { children: rest.label }), state, inputRef), inputProps = _c.inputProps, isDisabled = _c.isDisabled, isSelected = _c.isSelected;
    var _d = useFocusRing({ within: true }), focusProps = _d.focusProps, isFocusVisible = _d.isFocusVisible;
    return (<Grid as='label' gridTemplateColumns='auto 1fr' alignItems='center' justifyContent='start' gap='space-0.75' {...hoverProps} {...(variant === 'boxed' ? {
        border: 'greyLine',
        borderRadius: 'small',
        padding: 'space-0.75',
        paddingBottom: 'space-1.25',
    } : {})}>
      <Box display='flex' {...focusProps} className={isFocusVisible ? focusRing : undefined}>
        <span {...forwardClickProps} style={{ height: renderIcon ? undefined : '20px' }}>
          {renderIcon ? renderIcon({
            isSelected: isSelected,
            isHovered: isHovered,
            isDisabled: isDisabled,
        }) : (<RadioIcon state={isSelected ? 'selected' : 'deselected'} hovered={isHovered} disabled={isDisabled} aria-hidden/>)}
        </span>
        {/* The visually hidden input element will still capture all of the normal
          events, like focus as well as forwarded click events from an
          associated label */}
        <VisuallyHidden>
          <input type='radio' ref={inputRef} {...inputProps}/>
        </VisuallyHidden>
      </Box>
      <div>
        {childrenAreLiteral(rest.label) ? <Text>{rest.label}</Text> : rest.label}
      </div>
      {rest.description && (<InputDescription gridColumnStart='2'>
          {rest.description}
        </InputDescription>)}
    </Grid>);
};
var RadioOutlineVariants = {
    deselected: {
        stroke: vars.color.foreground.greyLine,
        fill: undefined,
    },
    deselectedHovered: {
        stroke: vars.color.foreground.black,
        fill: undefined,
    },
    selected: {
        stroke: vars.color.foreground.greyLine,
        fill: undefined,
    },
    selectedHovered: {
        stroke: vars.color.foreground.black,
        fill: undefined,
    },
    disabled: {
        stroke: undefined,
        fill: vars.color.foreground.greyLine,
    },
    disabledSelected: {
        stroke: undefined,
        fill: vars.color.foreground.greyLine,
    },
};
var RadioFillingVariants = {
    deselected: {
        scale: 0,
        opacity: 0,
    },
    deselectedHovered: {
        scale: 0,
        opacity: 0,
    },
    selected: {
        scale: 1,
        opacity: 1,
    },
    selectedHovered: {
        scale: 1,
        opacity: 1,
    },
    disabled: {
        scale: 0,
        opacity: 0,
    },
    disabledSelected: {
        scale: 1,
        opacity: 1,
    },
};
export var RadioIcon = function (props) {
    var _a = props.height, height = _a === void 0 ? 20 : _a, _b = props.width, width = _b === void 0 ? 20 : _b, _c = props.state, state = _c === void 0 ? 'deselected' : _c, _d = props.hovered, hovered = _d === void 0 ? false : _d, _e = props.disabled, disabled = _e === void 0 ? false : _e, rest = __rest(props, ["height", "width", "state", "hovered", "disabled"]);
    var derivedState = (function () {
        if (disabled) {
            return state === 'selected' ? 'disabledSelected' : 'disabled';
        }
        if (state === 'selected') {
            if (hovered)
                return 'selectedHovered';
            return 'selected';
        }
        if (hovered)
            return 'deselectedHovered';
        return 'deselected';
    })();
    return (<svg height={height} width={width} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <g fill='none' fillRule='evenodd'>
        <rect fill='#fff' stroke='#dbdbdb' x='.5' y='.5' width='19' height='19' rx='9.5' strokeWidth={1} style={{
            fill: RadioOutlineVariants[derivedState].fill,
            stroke: RadioOutlineVariants[derivedState].stroke,
            transition: 'stroke 0.3s',
        }}/>
        <rect fill='#111' x='4' y='4' width='12' height='12' rx='6' style={{
            transform: "scale(".concat(RadioFillingVariants[derivedState].scale, ")"),
            transformOrigin: '50%',
            opacity: RadioFillingVariants[derivedState].opacity,
            transition: 'transform 0.3s, opacity 0.3s',
        }}/>
      </g>
    </svg>);
};
export default Object.assign(RadioGroup, {
    Item: RadioItem,
});
