import React from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

import { bemify, cn } from '../../webutils/webutils';

import styles from './Markdown.module.scss';

const bem = bemify(styles);

interface MarkdownProps {
  source?: string;
  className?: string;
  defaultStyles?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  style?: React.CSSProperties;
}

const Markdown: React.FC<MarkdownProps> = React.forwardRef((props, ref) => {
  const {
    source,
    className,
    defaultStyles = true,
    style,
  } = props;

  if (!source) {
    return null;
  }

  return (
    <div
      className={cn(defaultStyles && bem.container, className)}
      style={style}
      ref={ref}
    >
      <ReactMarkdown
        skipHtml
        disallowedElements={['image']}
        unwrapDisallowed
        plugins={[breaks]}
        components={{
          // this is not typed anywhere, be careful with it
          link: (props) => {
            const href = props.href as string;
            if (href.toLowerCase().startsWith('http')) {
              // Open external links safely in new tabs
              return (
                <a
                  href={href}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {props.children}
                </a>
              );
            }

            // Open internal links normally
            return (
              <a href={href}>{props.children}</a>
            );
          },
          strong: props => (<span style={{ fontWeight: 'bold' }} {...props} />),
        }}
      >
        {source}
      </ReactMarkdown>
    </div>
  );
});

export default Markdown;
