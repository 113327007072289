import React from 'react';

import { MableURI } from '@mablemarket/mable-lib';

import Banner from '../Banner';
import { IconWarning } from '../Icons/IconWarning';
import TextLink from '../TextLink';

const DataComplianceWarning = () => (
  <Banner palette='white' border>
    <Banner.Icon>
      <IconWarning />
    </Banner.Icon>
    <Banner.Content>
      Some of your product attributes are not editable due
      {' '}
      to our partners&apos; data compliance requirements. Please fill out
      {' '}
      <TextLink foreground='black' underline href={MableURI.support({ topic: 'partnership-product-change-request' })}>
        this form
      </TextLink>
      {' '}
      if you intend to make changes.
    </Banner.Content>
  </Banner>
);

export default DataComplianceWarning;
