import React from 'react';
import { Route, RouteProps } from '@mablemarket/common-react-lib/build/modules/NextRouter/components';
import { useAuth0 } from './Auth0Provider';
import Loading from './components/Loading';
import useNextLocation from '@mablemarket/common-react-lib/build/modules/NextRouter/hooks/useNextLocation';

const PrivateRoute = (props: RouteProps) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useNextLocation();

  if (loading) {
    console.error('This should never happen');
  }

  if (!isAuthenticated) {
    const redirect = [
      location.pathname,
      location.hash,
      location.search,
    ].filter(x => x).join('');
    loginWithRedirect({
      appState: { targetUrl: redirect },
    });
    return <Loading />;
  }

  return (
    <Route {...props} />
  );
}

export default PrivateRoute;
